import React, {useState,useEffect }       from "react";
import $                                  from "jquery";
import Swal                               from "sweetalert2";
import * as helper                        from "../global/Helper";
import {addContactUs}                     from "../api/webAPI";


import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";

const ContactUsModal = (props) => {
  
  const [contactData,setcontactData] = useState({});
  
  const contactDataHandler = (key, value) => {
    setcontactData(d=>{
      return {...d,[key]:value};
    });
  };
  
  const submitContactUs = async (e) => {
    e.preventDefault();
    var form = $('#contact-form');

    form.validate().destroy();
    form.validate({
      rules:{
        txtContactName:{
          required:true
        },
        txtContactEmail:{
          required:true,
        },
        txtSubject:{
          required:true,
        },
      }
    });

    if(!form.valid()){
      if ($("#txtContactName-error").text() !== "") {
        $("#txtContactName").addClass("is-invalid");
      }
      if ($("#txtContactEmail-error").text() !== "") {
        $("#txtContactEmail").addClass("is-invalid");
      }
      if ($("#txtSubject-error").text() !== "") {
        $("#txtSubject").addClass("is-invalid");
      }
      return;
    }

    try {
      helper.StartProcessing($("#btnContactUs"));
      await addContactUs(contactData);
      $("#ContactUs-modal").modal("hide");
      Swal.fire({
        icon: "success",
        titleText: "Request Submitted",
        text: "Thank You..!",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      helper.StopProcessing($("#btnContactUs"));
      Swal.fire({
        icon: "error",
        titleText: "Error!",
        text: err.message,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-brand",
      });
    }  
  }

  
  //Modal Opener
  useEffect(() => {
    $("#ContactUs-modal").modal({
      backdrop: "static",
    });

    $("#ContactUs-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      props.onDismissModal();
    });

    $("#ContactUs-modal").modal("toggle");
  }, [props.show]);

  return (
    //  <!--Modal Contact us -->
    <div
      className="modal fade "
      id="ContactUs-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog modal-lg" role='document'>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" >
              Contact Us
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div id="modalHolderContact">
              <div id="messageHolderContact"></div>
              <form id="contact-form" className="contact">
                <input
                  className="contact-input white-input margin-t0"
                  name="txtContactName"
                  id="txtContactName"
                  placeholder="Name*"
                  type="text"
                  value={contactData.Name || ""}
                  onChange={({ target }) =>contactDataHandler("Name", helper.titleCase(target.value))}
                />
                <input
                  className="contact-input white-input"
                  name="txtContactEmail"
                  id="txtContactEmail"
                  placeholder="Email*"
                  type="email"
                  value={contactData.Email || ""}
                  onChange={({ target }) =>contactDataHandler("Email", target.value)}
                />
                <input
                  className="contact-input white-input"
                  name="txtSubject"
                  id="txtSubject"
                  placeholder="Subject"
                  type="text"
                  value={contactData.Subject || ""}
                  onChange={({ target }) =>contactDataHandler("Subject",helper.titleCase(target.value))}
                />
                <textarea
                  className="contact-commnent white-input"
                  rows="2"
                  cols="20"
                  name="txtMessage"
                  id="txtMessage"
                  placeholder="Your Message..."
                  value={contactData.Message || ""}
                  onChange={({ target }) =>
                  contactDataHandler("Message", target.value)
                  }>
                </textarea>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal">
              Close
            </button>
            <button type="button" id="btnContactUs" className="btn btn-primary" onClick={submitContactUs}>
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
    // {/* <!-- /#Modal --> */}
  );
};
export default ContactUsModal;
