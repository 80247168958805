import React, {useState,useEffect }       from "react";
import $                                  from "jquery";
import Swal                               from "sweetalert2";
import * as helper                        from "../global/Helper";
import {addBookDemo}                      from "../api/webAPI";

import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";


const RequestDemoModal = (props) => {
  
    const [demoData, setDemoData] = useState({});
    
    const demoDataHandler = (key, value) => {
        setDemoData(d=>{
        return {...d,[key]:value};
      });
    };
    
    const submitDemoRequest = async (e) => {
      e.preventDefault();
      var form = $('#contact-form');
  
      form.validate().destroy();
      form.validate({
        rules:{
          txtContactName:{
            required:true
          },
          txtClubName:{
            required:true
          },
          txtDesignation:{
            required:true,
          },
          txtContactEmail:{
            required:true,
          },
          txtContact:{
            required:true,
          }
        }
      });
  
      if(!form.valid()){
        if ($("#txtContactName-error").text() !== "") {
          $("#txtContactName").addClass("is-invalid");
        }
        if ($("#txtClubName-error").text() !== "") {
            $("#txtClubName").addClass("is-invalid");
        }
        if ($("#txtDesignation-error").text() !== "") {
            $("#txtDesignation").addClass("is-invalid");
        }
        if ($("#txtContactEmail-error").text() !== "") {
            $("#txtContactEmail").addClass("is-invalid");
        }
        if ($("#txtContact-error").text() !== "") {
            $("#txtContact").addClass("is-invalid");
        }
        return;
      }
  
      try {
        helper.StartProcessing($("#btnContactUs"));
        await addBookDemo(demoData);
        $("#ContactUs-modal").modal("hide");
        Swal.fire({
          icon: "success",
          titleText: "Request Submitted",
          text: "Thank You..!",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        helper.StopProcessing($("#btnContactUs"));
        Swal.fire({
          icon: "error",
          titleText: "Error!",
          text: err.message,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-brand",
        });
      }  
    }
  
    
    //Modal Opener
    useEffect(() => {
      $("#ContactUs-modal").modal({
        backdrop: "static",
      });
  
      $("#ContactUs-modal").on("hidden.bs.modal", function () {
        $(".modal").hasClass("show") && $("body").addClass("modal-open");
        props.onDismissModal();
      });
  
      $("#ContactUs-modal").modal("toggle");
    }, [props.show]);
  
    return (
      //  <!--Modal Contact us -->
      <div
        className="modal fade "
        id="ContactUs-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">
        <div className="modal-dialog modal-lg" role='document'>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" >
                Request A Demo
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="modalHolderContact">
                <div id="messageHolderContact"></div>
                <form id="contact-form" className="contact">
                  <input
                    className="contact-input white-input margin-t0"
                    name="txtContactName"
                    id="txtContactName"
                    placeholder="Name*"
                    type="text"
                    value={demoData.Name || ""}
                    onChange={({ target }) => demoDataHandler("Name", helper.titleCase(target.value))}
                  />
                  <input
                    className="contact-input white-input"
                    name="txtClubName"
                    id="txtClubName"
                    placeholder="Club Name*"
                    type="text"
                    value={demoData.ClubName || ""}
                    onChange={({ target }) => demoDataHandler("ClubName", helper.titleCase(target.value))}
                  />
                  <input
                    className="contact-input white-input"
                    name="txtDesignation"
                    id="txtDesignation"
                    placeholder="Designation*"
                    type="text"
                    value={demoData.Designation || ""}
                    onChange={({ target }) => demoDataHandler("Designation", target.value)}
                  />
                  <input
                    className="contact-input white-input"
                    name="txtContactEmail"
                    id="txtContactEmail"
                    placeholder="Email*"
                    type="email"
                    value={demoData.Email || ""}
                    onChange={({ target }) => demoDataHandler("Email", target.value)}
                  />
                  <input
                    className="contact-input white-input"
                    name="txtContact"
                    id="txtContact"
                    placeholder="Mobile Number*"
                    type="tel"
                    value={demoData.Contact || ""}
                    onChange={({ target }) => demoDataHandler("Contact", target.value)}
                  />
                  <textarea
                    className="contact-commnent white-input"
                    rows="2"
                    cols="20"
                    name="txtMessage"
                    id="txtMessage"
                    placeholder="Your Message..."
                    value={demoData.Message || ""}
                    onChange={({ target }) =>
                    demoDataHandler("Message", target.value)
                    }>
                  </textarea>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal">
                Close
              </button>
              <button type="button" id="btnContactUs" className="btn btn-primary" onClick={submitDemoRequest}>
                Request
              </button>
            </div>
          </div>
        </div>
      </div>
      // {/* <!-- /#Modal --> */}
    );
  };
  export default RequestDemoModal;