import {axiosPost, axiosGet} from "./axiosFunctions";

const addContactUsUrl               = "/api/homepage/addContactUs";
const addBookDemoUrl                = "/api/homepage/addBookDemo";
const getBlogsUrl                   = "/api/blog/getBlogs";
const getBlogByAliasUrl             = "/api/blog/getBlogByAlias";
const getAuthorBacklinksUrl         = "/api/blog/getAuthorBacklinksActive";
const getTestimonialUrl             = "/api/homepage/getTestimonialAll";
const getClientAllUrl               = "/api/homepage/getClientAll"; 


//Add API's =>

//Contact Us -->
export const addContactUs = async (contactUsData) => {
    const response = await axiosPost(
        addContactUsUrl,
        {contactUsData},
    );
    return response;
};

export const addBookDemo = async (bookDemoData) => {
    const response = await axiosPost(
        addBookDemoUrl,
        {bookDemoData},
    );
    return response;
};

//Get API's =>

//Blogs -->
export const getBlogList = async() =>{
    const response = await axiosGet(getBlogsUrl,);
    return response;
}
export const getAuthorBacklinks = async(authorID) => {
    const response = await axiosGet(`${getAuthorBacklinksUrl}/${authorID}`,);
    return response;
}

//Check Blog By Alias
export const getBlogByAlias = async (blogAlias) => {
    const response = await axiosGet(`${getBlogByAliasUrl}/${blogAlias}`, );
    return response;
}

//Testimonials->
export const getTestimonialAll = async () => {
    const response = await axiosGet(getTestimonialUrl,);
    return response;
}

//Clients->
export const getClientAll = async () => {
    const response = await axiosGet(getClientAllUrl,);
    return response;
}