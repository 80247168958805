import React                        from "react";
import bookingUpload                from "../../assets/images/Booking_Upload.png"
import policystatus                 from "../../assets/images/Policy_Status.png";
import quotation                    from "../../assets/images/Quotation.png";
import endorsement                  from "../../assets/images/Endorsement.png";
import reports                      from "../../assets/images/reports_img.png";
import membershipManagement         from "../../assets/images/members_img.png";

const FeaturesSection = () => {

  const featureBoxStyle = {
    visibility: "visible",
    animationDelay: "0.25s",
    animationName: "fadeIn",
  };

  return (
    <section
      className="section-grey section-top-border padding-b100"
      id="features">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-10">
            <h2 className="section-title">CLUBEZ Club Management Software Modules</h2>
            <div className="colored-line"></div>
            <p>Key Features and Benefits</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={bookingUpload} className="img-features" alt=" " />
              <h4>Activity Booking Management</h4>
              <p>
              Easily book sports activities, classes, and events through our user-friendly interface.
              Manage and schedule bookings for various sports facilities, including courts, fields, swimming pools, and more.
              Avoid double bookings and conflicts with our intelligent reservation 

              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={membershipManagement} className="img-features" alt=" " />
              <h4>Membership Management</h4>
              <p>
              Maintain a comprehensive database of members with easy registration, renewal, and profile management.
              Track membership details, payment history, and attendance records effortlessly.
              Generate reports and analytics to gain insights into member engagement and usage patterns.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={policystatus} className="img-features" alt=" " />
              <h4>Facility Management</h4>
              <p>
                Simplify facility management by efficiently allocating resources such as courts, fields, and equipment.
                Monitor availability, maintenance schedules, and occupancy levels in real-time.
                Optimize facility usage and maximize revenue potential by identifying peak times and popular activities.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={quotation} className="img-features" alt=" " />
              <h4>Communication and Notifications</h4>
              <p>
              Keep members informed and engaged through personalized communication channels.
              Send automated reminders, updates, and announcements via email or push notifications.
              Foster a sense of community by facilitating interaction between members through discussion forums and social features.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={endorsement} className="img-features" alt=" " />
              <h4>Financial Management</h4>
              <p>
              Streamline financial operations by automating payment collection, invoicing, and receipt generation.
              Integrate with popular payment gateways for secure online transactions.
              Track revenue, expenses, and generate financial reports to monitor the club's financial health.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={reports} className="img-features" alt=" " />
              <h4>Reporting and Analytics</h4>
              <p>
              Gain actionable insights into your club's performance, member engagement, and facility utilization.
              Access customizable reports and analytics dashboards to make data-driven decisions.
              Identify trends, patterns, and areas for improvement to enhance the overall club experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    
  );
};
export default FeaturesSection;
