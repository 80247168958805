import React                   from "react";
import $                       from "jquery";
import ibLogo                  from "../../assets/images/img_logo_all.png";
import { Link, useHistory }    from "react-router-dom";
import {  scrollPage }         from "./Helper";



const Header = (props) => {
  
  const history = useHistory();

  const scrollToId = () => {
    const element = document.getElementById('features');
    if(!element){ 
      history.push("/");
      setTimeout(()=>{
        document.getElementById('features').scrollIntoView({behavior: "smooth"});
      },200);
    }
  }

  const scrollToSport = () => {
    const element = document.getElementById('sports');
    if(!element){ 
      history.push("/");
      setTimeout(()=>{
        document.getElementById('sports').scrollIntoView({behavior: "smooth"});
      },200);
    }
  }

  const pushResource = (key) => {
    history.push(`/${key}`);
    scrollPage();
    if($(window).width() < 960)  $("#navbar-toggler").trigger("click");
  };

  const scrollHandler = () => {
    $("#ul-list").children("li").removeClass('current');
    const element = document.getElementById('home')
    if (element) {
      document.getElementById('home').scrollIntoView({behavior: "smooth"});
    }else{
      history.push("/");
    }
  }

  

  
  return (
    <header className="header">
      <nav id="nav-ib" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link to="/" onClick={() => scrollHandler()} className="navbar-brand">
              <img id="imgLogo" src={ibLogo} alt="ib-logo" />
            </Link>
            <button
              id="navbar-toggler"
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>
            <div className="navbar-collapse collapse" id="navbarCollapse">
              <ul id="ul-list" className="navbar-nav ml-auto">
                <li>
                  <a href="#home" onClick={()=> history.push("/")} id="menuHome">Home</a>
                </li>
                <li>
                  <a href="#features" id="menuFeatures" onClick={scrollToId}>Features</a>
                </li>   
                <li>
                  <a href="#sports"  id="menuSports" onClick={scrollToSport}>Sports Management</a>
                </li>
                <li>
                    <span
                      id="blog"
                      onClick={() => pushResource("blogs/all")}>
                      Blog
                    </span>
                </li>
                <li>
                  <span id="about" onClick={() => {pushResource("about")}} >About</span>
                </li>  
                <li>
                  <a href="#contact" onClick={()=>{props.modalHandler('contactUs')}}>Contact</a>
                </li>                      
                <li >
                  <span  className="sign-up-free">
                  <span id="menuBookDemo" onClick={()=>{props.modalHandler('requestDemo')}}
                    className="btn-signup-menu  text-center" >Request Demo</span>
                  </span>                 
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </nav>
  
    </header>
  );
};

export default Header;
