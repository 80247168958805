import React,{useEffect}                    from "react";
import {scrollPage, highlightNavlink}    from "../global/Helper";
import $                                    from "jquery";
import group                                from "../../assets/images/customer-success.png";
import problem                              from "../../assets/images/puzzle.png";
import security                             from "../../assets/images/security.png";
import customer                             from "../../assets/images/customer-service.png";
import Helmet from "react-helmet";

 

const About = () => {

    useEffect(()=>{
        scrollPage();
        highlightNavlink('about');
    },[]);  

    useEffect(()=>{
        const maxHeight = $($(".about-list").sort((a, b) => $(b).height() - $(a).height())[0]).height();
        $(".about-list").css('min-height', `${maxHeight}px`); 
    },[])
   

    return(
        <>
        <Helmet>
            <title>About Us</title>
            <meta name="description" content="We are a dedicated team of software professionals who are passionate about helping clubs streamline their operations, enhance member experiences, and achieve their goals with ClubEZ. With our robust and user-friendly software, we aim to simplify the complexities of club management, allowing you to focus on what matters most creating a thriving and engaged club community." />
        </Helmet>
        <section className="section-grey pb-2 margin-l10 margin-r10 " onScroll={() => highlightNavlink('about')}>
            <div className="container margin-b100">
                <div className="section-header padding-0 wow fadeIn animated" data-wow-offset="120" data-wow-duration="1.5s">
                    <h2 className="about-title">About Us</h2>
                    <div className="colored-line"></div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h5 className="about-head-title">Why Choose CLUBEZ?</h5>
                        <p className="about-paragragh ">
                        At Clubez, we understand the unique challenges that clubs and organizations face in managing their operations efficiently. 
                        That's why we have developed a state-of-the-art club management software that is tailored to meet the specific needs of clubs like yours.
                        Here's why you should choose our software to empower your club for success:
                        <br></br>
                        <br></br>
                        <b>Comprehensive Solution:</b> Our club management software offers a comprehensive suite of features and tools that streamline and automate various aspects of club management. 
                        From membership management and event planning to communication and financial tracking, our software provides an all-in-one solution to centralize your club's operations, saving you time, effort, and resources.
                        <br></br>
                        <br></br>
                        <b>Customizable and Scalable:</b> We understand that every club is unique, with its own set of requirements and processes. 
                        Our software is highly customizable, allowing you to tailor it to your club's specific needs. 
                        Whether you need to create custom membership categories, configure event registration workflows, or design personalized reports, our software can be adapted to fit your club's unique structure and operations.
                        Additionally, our software is scalable, capable of growing alongside your club as its needs evolve over time.
                        <br></br>
                        <br></br>
                        <b>User-Friendly Interface:</b>We believe that technology should be accessible to all users, regardless of their technical expertise. 
                        Our club management software features a user-friendly interface that is intuitive and easy to navigate. 
                        You and your club members will quickly adapt to the software, minimizing the learning curve and maximizing efficiency in managing club operations.
                        <br></br>
                        <br></br>
                        <b>Seamless Integration:</b> Our software seamlessly integrates with popular third-party applications and platforms, providing you with a cohesive ecosystem to enhance your club's operations. 
                        Whether it's integrating with payment gateways, email marketing platforms, or event management tools, our software ensures smooth data flow and eliminates the need for manual data entry and duplication.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h5 className="about-head-title"> Our Vision </h5>
                        <p className="about-paragragh">
                            We are passionate about transforming ideas into captivating online experiences. 
                            we believe in the power of vision to shape the digital landscape and drive success for clubs and organizations. 
                            Our vision encompasses innovation, creativity, and a relentless commitment to delivering cutting-edge club Management software that exceed expectations. 
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h5 className="about-head-title margin-b30"> Our Values </h5>
                    </div>
                </div>
                <div className="row pricing-row md">

                    {/* {Customer Success} */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="about-box">
                            <ul className="about-list">
                                <li className="about-title">Customer Success</li>
                                <img className="about-icons " src={group}  />
                                <li className="about-text">Your success is our priority. We are committed to providing exceptional service and support to ensure you achieve your club management goals.</li>
                            </ul> 
                        </div>
                    </div>

                    {/* {Problem Solving} */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="about-box">
                            <ul className="about-list">
                                <li className="about-title">Problem Solving</li>
                                <img className="about-icons" src={problem} />
                                <li className="about-text">Our club management software is more than just a tool; it's a solution-oriented platform designed to tackle your challenges and drive your club's success.</li>
                            </ul> 
                        </div>
                    </div>

                    {/* {Security} */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="about-box">
                            <ul className="about-list">
                                <li className="about-title">Security</li>
                                <img className="about-icons" src={security}  /> 
                                <li className="about-text"> Trust is earned through a commitment to security. Our software's security features are designed to provide a shield of protection, ensuring that your club's operations and member data are safeguarded at all times.</li>
                            </ul> 
                        </div>
                    </div>

                    {/* {Excellence in Customer Service} */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="about-box">
                            <ul className="about-list">
                                <li className="about-title">Customer Priority</li>
                                <img className="about-icons" src={customer}  />
                                <li className="about-text">We are always there for our customers and strive to deliver best-in-class service and troubleshooting.</li>
                            </ul> 
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </>
    )
}

export default About;