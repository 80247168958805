import React,{useEffect}                    from "react";
import { scrollPage,unHightlightNavlink }   from "../global/Helper";
import { Helmet }                                 from "react-helmet";

const PrivacyPolicy = () => {

  useEffect(()=>{
    scrollPage()
    unHightlightNavlink()
  },[])

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Our Privacy Policy governs your visit to clubez.co, and explains how we collect, safeguard and disclose information that results from your use of our Service."/>
      </Helmet>
      <section class="policy-section" id="features">
        <div class="container">
          <div class="section-header padding-0 wow fadeIn animated" data-wow-offset="120" data-wow-duration="1.5s">
            <h2 class="dark-text">Privacy Policy</h2>
          </div>
          <div class="row padding-15">
            <div class="col-xs-12 text-left">
                <p>We are happy to have you here, at  <a href="https://www.clubez.co" target="blank">www.clubez.co</a>  (“Website”). Please note that we provide our services through our website and mobile application, CLUBEZ (“App”) (Collectively the “Platform”).</p>
                <p>If You wish to continue using our Platform, You agree to provide us such information as detailed here and also agree to the terms of this Privacy Policy.</p>
                <p>This Privacy Policy has been drafted by Us in accordance with the Information Technology Act, 2000 and the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. This Privacy Policy is subject to the Terms of Use and constitute a valid and legally binding agreement between You and Us. The Platform and any services thereon are being provided to You as a service on a revocable, limited, non-exclusive, and non-transferable license.</p>
                <p>For Us to provide You the Services, it is essential for us to collect some basic information about You. Accordingly, You consent to the collection, storage and use of the information You disclose on our App in accordance with this Privacy Policy. If we decide to change our Privacy Policy, We will try our best to keep you informed, s so that You always know the information we collect, how we use it, and the purposes of the same.</p>
                <p>If You do not agree with this Policy or our Terms of use, please do not continue to use or access our Platforms or any part thereof.</p>
                <br></br>
                  <div class="colored-line"></div>              
                    <div class="text-center"><div class="section-description">1.The Information We Collect:</div></div>              
                  <div class="colored-line"></div>
                <br></br>
                <p>A. At each point, you will clearly know the information we are collecting from you. For using the App, We collect:</p>
                <ul>
                    <li>i. Your full name,</li>
                    <li>ii. Your e-mail address,</li>
                    <li>iii. Your mobile number,</li>
                    <li>iv. and such other data that You may have chosen to provide on the Platforms.</li>
                </ul>
                <p>B. When you visit Our Website, or use our App, Our software will automatically collect the following information:</p>
                <ul>
                  <li>i. Your IP address,</li>
                  <li>ii. The website from which you were referred to our website (e.g. if you followed a link);</li>
                  <li>iii. The webpages you are visiting on our website,</li>
                  <li>iv. The browser you are using and its display settings,</li>
                  <li>v. Your operating system; and/or</li>
                  <li>vi. The date and duration of your visit.</li>
                </ul>
                <p>C. We are not be responsible for the accuracy or correctness of any such information. You undertake to indemnify Us for any losses that We may suffer due to any claim raised against Us with respect to any data and/or information that is provided by You to Us which are not attributable to negligence, fraud or misrepresentation on Our part.</p>
                <p>D. During the registration process, You may decide to not provide such information to Us. In case you choose to decline to submit information on the App, We may not be able to provide certain services to You. Any information provided by You will not be considered as sensitive and confidential if it is freely available and/or accessible in the public domain.</p>
                <p>E. On Our Website, We use data collection devices such as "cookies" on certain pages to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist Us in providing Our services. We offer certain features that are only available through the use of a "cookie". You are always free to decline our cookies if Your browser permits, although in that case You may not be able to use certain features on the Platforms. Additionally, You may encounter "cookies" or other similar devices on certain pages of the Website that are placed by third parties. We do not control the use of cookies by third parties.</p>
                <p>F. You also understand that this service is an extension of the services offered by the Association / Societies (being our Direct Customer) of the Premises, whether registered or unregistered, and accordingly, the data mentioned in point 1 (a) & (b) above, will also be shared with them, as a part our Services, we share the Information with them for recording and security purposes, however, we take no guarantee of the way the information shall or will be used by them.</p>        
                <br></br>
                <div class="colored-line"></div>              
                  <div class="text-center"><div class="section-description">2. Additional Information:</div></div>              
                <div class="colored-line"></div>
                <br></br>
                <p>When You use Our services, We may collect information about You, with Your permission, in the following general categories:</p>
                <p>A. Location Information: When You use the Platforms through the telecommunication device, we may collect Your location data if you provide us specific permission in this regard. Although You provide us Your complete address, If You permit the App to access Your location through the permission system used by Your mobile operating system, We may also collect the precise location of Your device when the App is running in the foreground or background.</p>
                <p>B. Device Information: We may collect information about Your mobile device, including, for example, the hardware model, operating system and version, preferred language, unique device identifier, and mobile network information.</p>
                <br></br>
                <div class="colored-line"></div>              
                <div class="text-center"><div class="section-description">3. Use of Information:</div></div>              
                <div class="colored-line"></div>
                <br></br>
                <p>A. The information collected by Us through our Platforms may be used for the following purposes:</p>
                <ul>
                    <li>i. To provide Our services to You,</li>
                    <li>ii. Internal record keeping,</li>
                    <li>iii. To improve Our services or provide new services.</li>
                </ul>
                <p>B. We may periodically send promotional e-mails or messages on the App about special offers related to the App (e.g. information about enhancements / new features of the App/Platform)</p>
                <p>C. From time to time, We may use the information to customize the Website or App.</p>
                <p>D. You agree that We may use the collected information to:</p>
                <ul>
                    <li>i. Compile, or analyse any information provided by You, on an aggregated basis only. We will do so without personally identifying You or Your personal information,</li>
                    <li>ii. We don’t sell or trade your data to/with anyone. We only use it for the purposes of making your experience better by introducing real time entries, permissions, etc or to provide you value added third party services.</li>
                </ul>
                <p>E. We may use the information provided by You to resolve disputes that may arise with the use of Our Services, help promote a safe service to all the Users of the Platforms, measure consumer interest in our services, customize your experience, detect and protect Us against error, fraud and other criminal activity and to enforce Our terms and conditions.</p>
                <p>F. We identify and use Your IP address to help diagnose problems with Our server, and to administer Our Platforms.</p>
                <br></br>
                <div class="colored-line"></div>              
                  <div class="text-center"><div class="section-description">4. Sharing of Information:</div></div>              
                <div class="colored-line"></div>
                <br></br>
                <p>A. We have to disclose your Information, if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose this information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to enforce our Terms or Privacy Policy.</p>
                <p>B. Your Information will be shared with another business entity should We (or our assets) plan to merge with, or be acquired by another business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur, the new business entity will follow this Privacy Policy.</p>
                <p>C. We do not disclose information provided by You to advertisers in any form that discloses Your personal information</p>
                <br></br>
                <div class="colored-line"></div>              
                  <div class="text-center"><div class="section-description">5. Information Safety:</div></div>              
                <div class="colored-line"></div>
                <br></br>
                <p>A. All information is saved and stored on servers, which are secured with passwords and pins to ensure no unauthorised person has access to it. Once Your information is in our possession, we adhere to strict security guidelines, and to the best extent possible protecting it against unauthorized access.</p>
                <p>B. We store your information over secure cloud service providers who offer security standards competitive with the industry norms. We also protect our servers with firewalls and data encryption using SSL, and information access authorization controls. We use reasonable safeguards to preserve the integrity and security of Your information against loss, theft, unauthorised access, disclosure, reproduction, use or amendment. To achieve the same, We use reasonable security practices and procedures as mandated under applicable laws for the protection of Your information. Information You provide to Us may be stored on Our secure servers located within or outside India.</p>
                <p>C. However, You understand and accept that there is no guarantee that data transmission over the Internet will be completely secure and that any information that You transmit to Us is at Your own risk. We assume no liability for any disclosure of information due to errors in transmission issues, unauthorized third-party access to our Platform and data bases or other acts of third parties or acts or omissions beyond Our reasonable control and You shall not be entitled to hold the Company responsible for any breach of security except in case of wilful negligence on the part of the Company.</p>
                <br></br>
                <div class="colored-line"></div>
                  <div class="text-center"><div class="section-description">6. Choice/Opt-Out:</div></div>              
                <div class="colored-line"></div>   
                <br></br>        
                
             <p>A. We provide all Users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from Us on behalf of our partners, and from Us in general, after providing Us with information. If You want to remove your contact information from all lists and newsletters, please contact team@clubez.co</p>
             <p>B. If You choose to unsubscribe from the Platform or delete any or all of your information, you may uninstall the App or delete Your account on the Website. You may also send an e-mail to team@clubez.co Please note, We may still retain some information and record of transactions as required by any law, contract or policy applicable to Us.</p>

             <p><em>“Us”, “We”, or the “Company” refers to Amasan Technologies LLP. The term “You” or “User” refers to you (a registered user, customer, merchant, visitor, third party service provider, or any visitor of the Platforms). The Website, the App, and any other online/offline platform where information is collected by the Company shall be collectively referred to as “Platforms”.</em></p>
             <p><em>By visiting / accessing the App, through the website or the app and voluntarily providing us with information (personal and/ or non- personal), you are consenting to our use of it in accordance with this Privacy Policy. This policy does not apply to third-party websites/ applications that are connected via links to the platform. If you do not agree with the terms and conditions of this Privacy Policy, please do not proceed further to use or access the Platform / Website / App, or any part thereof.</em></p>   
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PrivacyPolicy;
