import React                        from "react";
import golf1                        from "../../assets/images/golf (1).png"
import tennis                       from "../../assets/images/tennis.png"
import tableTennis1                 from "../../assets/images/table-tennis.png"
import cricket                      from "../../assets/images/cricket.png"
import footBall                     from "../../assets/images/soccer-player.png"
import squash                       from "../../assets/images/squash.png"
import badminton                    from "../../assets/images/badminton.png"
import gym                          from "../../assets/images/dumbbell.png"
import swimming                     from "../../assets/images/swimming.png"
import pool                         from "../../assets/images/pool.png"
import billiards                    from "../../assets/images/snooker.png"
const SportsSection = () => {

    const featureBoxStyle = {
      visibility: "visible",
      animationDelay: "0.25s",
      animationName: "fadeIn",
    };
  
    return (
      <section
        className="section-grey section-top-border padding-b100"
        id="sports">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center padding-bottom-10">
              <h2 className="section-title">Sports Management</h2>
              <div className="colored-line"></div>
              <p>Different Sports</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 sportsBlock">
              <div
                className="feature-box  fadeIn"
                data-wow-delay="0.25s"
                style={featureBoxStyle}>
                <img src={golf1} className="sport-img-features" alt=" " />
                <h4>Golf</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sportsBlock">
              <div
                className="feature-box  fadeIn"
                data-wow-delay="0.25s"
                style={featureBoxStyle}>
                <img src={tennis} className="sport-img-features" alt=" " />
                <h4>Tennis</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sportsBlock">
              <div
                className="feature-box  fadeIn"
                data-wow-delay="0.25s"
                style={featureBoxStyle}>
                <img src={tableTennis1} className="sport-img-features" alt=" " />
                <h4>Table Tennis</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sportsBlock">
              <div
                className="feature-box  fadeIn"
                data-wow-delay="0.25s"
                style={featureBoxStyle}>
                <img src={badminton} className="sport-img-features" alt=" " />
                <h4>Badminton</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sportsBlock">
              <div
                className="feature-box  fadeIn"
                data-wow-delay="0.25s"
                style={featureBoxStyle}>
                <img src={squash} className="sport-img-features" alt=" " />
                <h4>Squash</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sportsBlock">
              <div
                className="feature-box  fadeIn"
                data-wow-delay="0.25s"
                style={featureBoxStyle}>
                <img src={cricket} className="sport-img-features" alt=" " />
                <h4>Cricket</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sportsBlock">
              <div
                className="feature-box  fadeIn"
                data-wow-delay="0.25s"
                style={featureBoxStyle}>
                <img src={swimming} className="sport-img-features" alt=" " />
                <h4>Swimming</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sportsBlock">
              <div
                className="feature-box  fadeIn"
                data-wow-delay="0.25s"
                style={featureBoxStyle}>
                <img src={gym} className="sport-img-features" alt=" " />
                <h4>Gym</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sportsBlock">
              <div
                className="feature-box  fadeIn"
                data-wow-delay="0.25s"
                style={featureBoxStyle}>
                <img src={billiards} className="sport-img-features" alt=" " />
                <h4>Billiards</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  export default SportsSection;