import React,{useState, useEffect}  from "react";
import Top                from "../../assets/images/Top.png";
import secureEasyImg      from "../../assets/images/secure_easy.png";
import { scrollPage }     from "../global/Helper";
import { getClientAll } from "../api/webAPI";

const HomeSection = (props) => {

  const [clientData,      setClientData]      =  useState([]);
  
  useEffect(() => {
      let ignore = false;
      const fetchClient = async () =>{
        try {
          const response = await getClientAll();
          if(response.length > 0) setClientData(response.filter(t => t.Active == "Y"));
        } catch (error) {
          Swal.fire({
            icon: "error",
            titleText: "Error!",
            text: error.message,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-brand",
          });
        }
      }
      if(!ignore){
        fetchClient();
      }
      return ()=>{
        ignore = true;
      }
  }, []);


  return (
    <>
    <section className="home-section home-section-blue" id="home">
      <div className="container">
        <div className="row">
          <div className="col-md-6 padding-top-80">
            <h1 className="head-1">
              Welcome to CLUBEZ Streamlining Sports Club Management
            </h1>
            <div className="mb-2">
              <span id="menuBookDemo" onClick={()=>{props.modalHandler('requestDemo')}}
                className="btn-signup-menu  text-center" > Request Demo 
              </span> 
            </div>               
          </div>
          <div className="col-md-6 padding-left-100">
            <img src={Top} className="hero-image width-100" alt="pic" />
          </div>
        </div>
      </div>
    </section>

    <section className="" id="clients">
      <div className="" >
        <div className="section-light">
          <div className="col-md-12 text-center">
            <h3 className="section-title">Trusted by Prestigious Sports Clubs</h3>
          </div>
          <div className="scroller" data-speed="fast" data-animated="true">
            <ul className="tag-list scroller__inner">
            {clientData.length > 0 && clientData.map((t,i)=>(
              <li className= "images-wrapper" aria-hidden="true" key={i}>
                <a href={t.Url?t.Url:""} target="_blank">
                <img style={{height:'80px'}}  src={t.Logo}/>
                </a>
              </li>
            ))}
            {clientData.length > 0 && clientData.map((t,i)=>(
              <li className= "images-wrapper" key={i}>
                <a href={t.Url?t.Url:""} target="_blank">
                <img style={{height:'80px'}}  src={t.Logo}/>
                </a>
              </li>
            ))}
            {clientData.length > 0 && clientData.map((t,i)=>(
              <li className= "images-wrapper" key={i}>
                <a href={t.Url?t.Url:""} target="_blank">
                <img style={{height:'80px'}}  src={t.Logo}/>
                </a>
              </li>
            ))}
            {clientData.length > 0 && clientData.map((t,i)=>(
              <li className= "images-wrapper" key={i}>
                <a href={t.Url?t.Url:""} target="_blank">
                <img style={{height:'80px'}}  src={t.Logo}/>
                </a>
              </li>
            ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="section-white margin-t10">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-10">
            <h2 className="section-title">Our Management System is Safe, Secure, Easy To Use</h2>
            <div className="colored-line"></div>
          </div>
        </div>
        <div className="row justify-content-center  padding-sm-lr20">
            <img src={secureEasyImg} />
        </div>
      </div>
    </section>
    </>
  );
};
export default HomeSection;
