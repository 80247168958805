import React,{useState}            from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Footer                      from "../components/global/Footer";
import Header                      from "../components/global/Header";
import HomeSection                 from "../components/home/HomeSection";
import InformationSection          from "../components/home/InformationSection";
import FeaturesSection             from "../components/home/FeaturesSection";
import SportsSection               from "../components/home/SportsSectio";
import Testimonial                 from "../components/home/Testimonial";
import Blogs                       from "../components/pages/Blogs";
import PrivacyPolicy               from "../components/pages/PrivacyPolicy";
import ContactUsModal              from "../components/modals/ContactUsModal";
import RequestDemoModal            from "../components/modals/RequestDemoModal";       
import About                       from "../components/pages/About";
import { Helmet }                  from "react-helmet";



function Admin() {

    const [showContactUs,       setshowContactUs] = useState(false);
    const [showRequestDemo,     setshowRequestDemo] = useState(false);

    const modalHandler = (modal) => {
        switch(modal){
            case 'contactUs': 
                setshowContactUs(true);
                break;
            case 'requestDemo': 
                setshowRequestDemo(true);
                break;
            default:
                break;
        }
    }


    return (
        <>
            <Helmet>
                <title>
                    Streamlining Sports Club Management - Clubez.co
                </title>
                <meta name="description"
                    content="Efficiently manage your sports club's activities, bookings, and facilities with CLUBEZ, the leading sports club management application."
                />
            </Helmet>

            <Switch>
                <Route path="/">
                    <Header  modalHandler={modalHandler}  />
                    <Switch>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/privacy-policy">
                            <PrivacyPolicy />
                        </Route>
                        <Route path="/blogs/:tag">
                            <Blogs />
                        </Route>
                        <Route path="/blogs">
                            <Redirect  to="/blogs/all" />
                        </Route>
                        <Route path="/">
                            <HomeSection modalHandler={modalHandler}/>
                            <FeaturesSection />
                            <SportsSection />
                            <InformationSection modalHandler={modalHandler}/>
                            <Testimonial />
                            <div className="container">
                                <div className="row justify-content-center padding-t15 margin-b30"><span className="btn-signup color-white text-center" onClick={()=>{modalHandler('requestDemo')}}><span>Request Demo</span></span></div>
                            </div>
                        </Route>
                    </Switch>
                    <Footer
                        modalHandler={modalHandler}
                    />
                </Route>
            </Switch>
                        
            {showContactUs && 
                <ContactUsModal 
                    show={showContactUs}
                    onDismissModal={() => setshowContactUs(false)}
                />
            }
            {showRequestDemo && 
                <RequestDemoModal 
                    show={showRequestDemo}
                    onDismissModal={() => setshowRequestDemo(false)}
                />
            }
        </>
    );
}

export default Admin;
