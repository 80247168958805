import React, { useState, useEffect, useLayoutEffect}   from "react";
import $                                                from "jquery";
import userImg                                          from "../../assets/images/user.png";
import rightQuote                                       from "../../assets/images/rightquote.png"
import leftQuote                                        from "../../assets/images/leftquote.png"
import Swal                                             from "sweetalert2";
import { getTestimonialAll }                            from "../api/webAPI";
import { appUrl }                                       from "../global/Helper";

const Testimonial = () => {


    const [testimonialList, setTestimonialList] = useState([]);

    useLayoutEffect(() => {
        const maxHeight = $($(".card-content").sort((a, b) => $(b).height() - $(a).height())[0]).height();
        $(".card-content").css('min-height', `${maxHeight}px`);
    })


    useEffect(() => {
        let ignore = false;
        const fetchTestimonial = async () =>{
          try {
            const response = await getTestimonialAll();
            if(response.length > 0) setTestimonialList(response.filter(t => t.Active == "Y"));
          } catch (error) {
            Swal.fire({
              icon: "error",
              titleText: "Error!",
              text: error.message,
              buttonsStyling: false,
              confirmButtonClass: "btn btn-brand",
            });
          }
        }
        if(!ignore){

          fetchTestimonial();
        }
        return ()=>{
          ignore = true;
        }
    }, []);

    return (
        testimonialList.length > 0 && (
            <section className="section-grey medium-paddings section-bottom-border" id="testimonial">
                <div className="container testimonial ">
                    <div className="row">
                        <div className="col-md-12 text-center padding-bottom-30">
                            <h2 className="section-title">Testimonials</h2>
                            <div className="colored-line"></div>
                            <p>You can relay on our amazing features and on our customer services. </p>
                        </div>
                    </div>
                
                    <div className="row">
                        {testimonialList.map((t,i)=>{
                            return(
                                <div className="col-lg-4 col-12 card1  padding-bottom-40 "key={i}>
                                    <div className="card testimonial-card card-shadow"> 
                                        <div className={`card-body  ${t.ID === 1 && "border-0"} ${t.ID === 2 && "maxHeight"} `}>
                                            <h6 className="card-content font-weight-light"> 
                                                <img src={leftQuote} alt="leftQuote" className="leftQuote mr-2 mb-2" ></img> 
                                                    {t.Content} 
                                                <img src={rightQuote} alt="rightQuote" className="rightQuote ml-2 mb-2" ></img> 
                                            </h6>
                                            <div className="card-extra">
                                                <span className="thumb-img"><img src={t.Image ?t.Image:userImg} alt="wrapkit" className="rounded-circle"/></span>
                                                <div className="customer">
                                                    <h6 className="customer-title">{t.Name}</h6>
                                                    <span className="customer-extra">{t.Designation}</span>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                            )
                        })}
                    </div>
                </div>
            </section>
        )
    );
};
export default Testimonial;
