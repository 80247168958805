import $ from 'jquery';
import jQuery from 'jquery';

/* eslint-disable */
"use strict";
var valGetParentContainer = function(element) {
    var element = $(element);

    if ($(element).closest('.form-group-sub').length > 0) {
        return $(element).closest('.form-group-sub')
    } else if ($(element).closest('.bootstrap-select').length > 0) {
        return $(element).closest('.bootstrap-select')
    } else {
        return $(element).closest('.form-group');
    }
}

jQuery.validator.setDefaults({
    errorElement: 'div', //default input error message container
    focusInvalid: false, // do not focus the last invalid input
    //ignore: "",  // validate all fields including form hidden input
    ignore: "[contenteditable='true']:not([name])",

    errorPlacement: function(error, element) { // render error placement for each input type
        var element = $(element);

        var group = valGetParentContainer(element);
        var help = group.find('.form-text');

        if (group.find('.valid-feedback, .invalid-feedback').length !== 0) {
            return;
        }

        element.addClass('is-invalid');
        error.addClass('invalid-feedback');
        if (element.parent().hasClass("css-2b097c-container")) {
            element.parent().children(".css-yk16xz-control").find("input").focus(() => {
                error.remove();
            })
        }

        if (help.length > 0) {
            help.before(error);
        } else {
            if (element.closest('.bootstrap-select').length > 0) {     //Bootstrap select
                element.closest('.bootstrap-select').wrap('<div class="bootstrap-select-wrapper" />').after(error);
            } else if (element.closest('.input-group').length > 0) {   //Bootstrap group
                element.after(error);
            } else {                                                   //Checkbox & radios
                if (element.is(':checkbox')) {
                    element.closest('.kt-checkbox').find('> span').after(error);
                } else {
                    element.after(error);
                }                
            }            
        }

    },

    highlight: function(element) { // hightlight error inputs
        var element = $(element);
        var group = valGetParentContainer(element);
        group.addClass('validate');
        element.addClass('is-invalid');
        element.removeClass('is-valid');
        if (element.parent().hasClass("css-2b097c-container")) {
            element.parent()
                .children(".css-yk16xz-control")
                .addClass("react-select-control-invalid");
        }
    },
    unhighlight: function(element) { // revert the change done by hightlight
        var element = $(element);
        var group = valGetParentContainer(element);
        group.removeClass('validate'); 
        element.removeClass('is-invalid'); 
        //element.addClass('is-valid');
    },
    success: function(label, element) {
        var group = valGetParentContainer(element);
        group.removeClass('validate');
        group.find('.invalid-feedback').remove();
    }
});

jQuery.validator.addMethod("email", function(value, element) {
    if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
        return true;
    } else {
        return false;
    }
}, "Please enter a valid Email.");