import React                        from "react";

const InformationSection = () => {
    return (
      <React.Fragment>
        <section className="section-white section-top-border">
          <div className="container">
            <div className="row margin-b30">
              <div className="col-md-12 text-center padding-bottom-10">
                <h2 className="section-title">Our Club Management Software Provides Everything You Need</h2>
                <div className="colored-line"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                Efficiently manage your sports club's activities, bookings, and facilities with CLUBEZ, the leading sports club management application. 
                Whether you run a small community sports club or a large-scale athletic facility, CLUBEZ simplifies your administrative tasks, enhances member experiences, and optimizes resource allocation. 
                Say goodbye to manual processes and hello to streamlined club operations!
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  };
  export default InformationSection;